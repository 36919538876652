<template>
  <v-card>
    <v-card-title> Descarrega Ordem </v-card-title>
    <v-card-text>
      <v-card class="my-2">
        <v-card-title>
          Ordem(s) para o usuário >
          <v-spacer> </v-spacer>
          <v-autocomplete
            v-model="userId"
            :items="getUserSelect"
            label="Selelcione o usuário"
          ></v-autocomplete>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="userId == null || userId == 0"
            color="success"
            @click="getList()"
            >Buscar</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Ordem de Compra</th>
                <th class="text-left">Item</th>
                <th class="text-left">Usuário Coleta</th>
                <th class="text-left">Quantidade</th>
                <th class="text-left">Quantidade Coletada</th>
                <th class="text-left">Data da Coleta</th>
                <th class="text-left">Descrição</th>
                <th class="text-left">Enviado Por</th>
                <th class="text-left">Cadastro</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getBOLoaded" :key="index">
                <td>{{ item.id }}</td>
                <!-- <td>{{ item.buyOrderId }}</td> -->
                <td>
                  <v-card>
                    <v-card-text>
                      <v-row justify="space-around">
                        <b>Nome:</b>
                        <p>
                          {{ getOrderById(item.buyOrderId).name }}
                        </p>
                      </v-row>
                      <v-row justify="space-around">
                        <b>Numero:</b>
                        <p>
                          {{ getOrderById(item.buyOrderId).buyOrder }}
                        </p>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </td>
                <!-- <td>{{ item.itemId }}</td> -->
                <td>
                  <OrdemItems
                    :item="getItemOrdemById(item.buyOrderId, item.itemId)"
                  />
                </td>
                <td>{{ getUserNameById(item.userId) }}</td>
                <td>{{ item.amount }}</td>
                <td>{{ item.colectedAmount }}</td>
                <td>
                  {{
                    item.loadedDate
                      ? new Date(item.loadedDate).toLocaleString()
                      : ""
                  }}
                </td>
                <td>{{ item.description }}</td>
                <td>{{ getUserNameById(item.createUserId) }}</td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>
                  <v-btn fab @click="finish(item)">
                    <v-icon color="success"> mdi-checkerboard </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-dialog v-model="dialogFNS" width="800">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Finalizar Ordem
          </v-card-title>

          <v-card-text>
            <v-row class="mt-3">
              <v-col>
                <v-textarea
                  outlined
                  rows="1"
                  label="Descrição / Observações"
                  v-model="comment"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col>
                <v-textarea
                  outlined
                  rows="1"
                  label="Placa"
                  v-model="plate"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="dialogFNS = false">
              Cancelar
            </v-btn>
            <v-btn color="warning" text @click="send()"> Finalizar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import OrdemItems from "../components/Ordem/Item";
export default {
  name: "finaliza",
  components: {
    OrdemItems,
  },
  data() {
    return {
      showForm: false,
      userId: null,
      dialogFNS: false,
      img: null,
      item: {},
      comment: "",
      plate: "",
    };
  },
  computed: {
    ...mapGetters([
      "getConstructions",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
      "getUserSelect",
      "getBOLoaded",
      "getItemOrdemById",
      "getOrderById",
      "getUserNameById",
    ]),
  },
  methods: {
    getList() {
      this.$store.dispatch("loadBOLoadedtoUserById", this.userId);
    },
    finish(item) {
      console.log(item);
      this.dialogFNS = true;
      this.item = item;
    },
    send() {
      let formData = new FormData();
      // this.response = "";
      // this.responseTime = 0;
      // this.regexResponse = [];
      formData.append("picture", this.img);
      // formData.append("regexid", this.regexId);
      this.$http
        .post("/ocUsuario/finish/" + this.item.id, {
          comment: this.comment,
          plate: this.plate,
        })
        .then((resp) => {
          if (resp.status < 299) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch("showSuccessSnack", "Ordem Finalizada");
            console.log(resp.data);
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("loadOrders");
    this.$store.dispatch("loadProviders");
    this.$store.dispatch("loadUsers");
  },
};
</script>

<style>
</style>